import { type MetaFunction, useLoaderData } from '@remix-run/react';
import { $path } from 'remix-routes';
import { type ExternalScriptsHandle } from 'remix-utils/external-scripts';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { useAnonAppAnalytics } from '../../src/analytics/app/anon';
import { PageManagerUtils } from '../../src/components/PageManager/utils';
import config from '../../src/config';
import { hubspotScripts } from '../../src/tracking/hubspot';
import { AnonProvider } from '../components/AnonLayout';
import { MetadataUtils } from '../components/metadata';
import { AnonPublicLibraryIndex } from '../components/PublicLibrary/AnonPublicLibraryIndex';
import { fetchGamePacksByTags } from '../fetches/fetchGamePacksByTags';
import { fetchMostPopularProduct } from '../fetches/fetchMostPopularProduct';
import { fetchPage } from '../fetches/fetchPage';

export const handle: ExternalScriptsHandle = {
  scripts: () => {
    return [...hubspotScripts()];
  },
};

export const meta: MetaFunction = () => {
  return MetadataUtils.Make({
    title: 'Explore Our Library of Team Building Experiences',
    desc: 'Discover and book dozens of live-hosted team building experiences your team will rave about',
    url: new URL($path('/live-experiences'), config.app.baseUrl).href,
    image: 'generic',
  });
};

export const loader = async () => {
  const pageName = EnumsPageName.PageNameLiveOtp;
  const page = await fetchPage(pageName);
  const product = await fetchMostPopularProduct();
  const gamePackTags = PageManagerUtils.GetGamePackTags(page);
  const groupedGamePacks = await fetchGamePacksByTags(
    gamePackTags.map((t) => t.id)
  );
  return {
    pageName,
    page,
    product: product || null,
    groupedGamePacks,
  };
};

export function shouldRevalidate() {
  return false;
}

function Container() {
  const data = useLoaderData<typeof loader>();
  const analytics = useAnonAppAnalytics();

  return (
    <AnonProvider>
      <AnonPublicLibraryIndex
        {...data}
        featuredItems={[]}
        programs={[]}
        hideCategoriesHeader
        analytics={analytics}
      />
    </AnonProvider>
  );
}

export default function Component() {
  return (
    <AnonProvider>
      <Container />
    </AnonProvider>
  );
}
